import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { HiArrowLeft } from "react-icons/hi";

import SVGIcon from "components/Icon";
import SvgIcon from "components/Icon/new";
import CustomButton from "components/Button";
import CustomInfobox from "components/Infobox";
import AtlasIcon from "components/Icon/atlasIcon";

import "./index.scss";

const ModuleHeader = ({
  title,
  moduleIcon,
  atlasIcon,
  description,
  showPABadge,
  backButton,
  actionButton,
  containerStyle,
  infoBoxInactiveContent,

  infoBoxContent,
  renderExtraButton,
  renderAlertContent,
}) => {
  const [showInfoBox, setShowInfoBox] = useState(false);

  return (
    <div className="at_module_header--cont" style={{ ...containerStyle }}>
      <div className="at_module_header--title-cont ">
        {backButton && backButton?.onShow && (
          <CustomButton
            className="btn-new-sm btn-new--outline-secondary"
            onClick={backButton.onClick}
            children={
              <HiArrowLeft
                style={{ height: 14, width: 14, color: "#374151" }}
              />
            }
          />
        )}
        {!(backButton && backButton?.onShow) && (
          <div className="module-header-icon">
            {moduleIcon && <SvgIcon style={{ fill: '#F0631D' }} svgHref={moduleIcon} />}
            {!moduleIcon && <AtlasIcon style={{ fill: '#F0631D' }} svgHref={atlasIcon || "atlas-note"} />}
          </div>
        )}
        <p>{title}</p>
        {showPABadge && (
          <Tooltip
            placement={"bottom"}
            title={`You are acting on behalf of your superior in this section.`}
          >
            <div className="mx-2">
              <SVGIcon
                style={{ fill: "#f18e03", width: 26, height: 30 }}
                svgHref={"icon-superior"}
              />
            </div>
          </Tooltip>
        )}
        {((actionButton && actionButton.length > 0) || renderExtraButton) && (
          <div className="at-module_header--action-cont">
            {actionButton.map((item, index) => {
              if (item?.onShow) {
                return (
                  <CustomButton
                    key={`module_header-action_button-${index}`}
                    className={`${item.className || "btn-new btn-new--primary"}`}
                    onClick={item.onClick}
                    disabled={item.disabled}
                    children={
                      <>
                        {item.icon}
                        {item.label}
                      </>
                    }
                    tooltip={!item.hideTooltip}
                    tooltipPosition={"bottom"}
                    tooltipID={`${title}-header-action-${item.label}`}
                    tooltipChildren={item.tooltipChildren || item.label}
                    additionalContent={item.additionalContent}
                  />
                );
              }
            })}
            {renderExtraButton && renderExtraButton()}
          </div>
        )}
      </div>
      {(renderAlertContent || infoBoxContent || description) && (
        <div className="at-module_header--subsection">
          {description && <p className="at-module_header--description">{description}</p>}
          {renderAlertContent && <div>{renderAlertContent}</div>}
          {infoBoxContent && (
            <CustomInfobox
              expand={showInfoBox}
              inactiveContent={
                infoBoxInactiveContent || "Click for more searching tips!"
              }
              activeContent={infoBoxContent()}
              onClickExpandInfo={(val) => setShowInfoBox(val)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ModuleHeader;
